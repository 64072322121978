@charset "UTF-8";

@import "materialize";

body {
	background: #eeefef;
}

.container {
	margin-top: 1em;
}

.btn, .switch, label, .collapsible-header {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

#nav-mobile li a {
	color: #333;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../font/material-design-icons/Material-Design-Icons.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

kbd {
	border-radius: 4px;
	border: 2px solid #ccc;
	border-bottom-width: 3px;
	margin-right: 5px;
	font-size: 14px;
	padding: 1px 5px;
}
